import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../App";
import Navigation from "./Navigation";
import { gsap, } from 'gsap'
import Footer from "./Footer";

export default function Home() {
    const { state } = useContext(AuthContext);
    const { t } = useTranslation();

    useEffect(() => {
        document.body.classList.add('bg-white', 'home');

        gsap.defaults({
            ease: "circ.inOut",
            stagger: 4,
        });

        let tl = gsap.timeline({
            onComplete: function () {
                tl.restart();
            }
        });

        let line = document.querySelectorAll(".line");
        let time = 0.9;
        let y = 100;

        tl.add(gsap.fromTo(line, {
            opacity: 0,
            y: y,
        }, {
            duration: 1.9,
            y: 0,
            opacity: 1,
        })).add(gsap.to(line, {
            delay: 0.4,
            duration: 2,
            opacity: 0,
            y: -y,
        }), 4);
    }, []);

    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div className="section">
                    <div className="splash-page mt-5 mb-5">
                        <div className="mb-3"><img src="assets/img/pagado_icon_shmedium.png" alt="image" className="imaged w-25 square" />
                        </div>
                        <img id="pagado-text" src="/images/pagado-text.svg" />
                    </div>
                    <div className="dynamic-text mt-5 mb-5">
                        <div className="line">Effortless Transactions</div>
                        <div className="line">Powered By Millix</div>
                        <div className="line">Versatile Payment Options</div>
                        <div className="line">Security</div>
                        <div className="line">Streamlined Integration</div>
                        <div className="line">Currency Conversion (Coming Soon)</div>
                    </div>
                </div>

                {state.isAuthenticated === false &&
                    <div className="section home-action-buttons">
                        <div className="row">
                            <div className="col-6">
                                <a href="/register" className="btn btn-lg btn-outline-secondary btn-block">{t('page.index.body.buttons.register')}</a>
                            </div>
                            <div className="col-6">
                                <a href="/login" className="btn btn-lg btn-primary btn-block">{t('page.index.body.buttons.sign_in')}</a>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Footer />
        </>
    );
}
