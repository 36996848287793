import axios from 'axios';
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../App";

export default function RemoteProfile() {
    const { state } = useContext(AuthContext);
    const params = useParams();
    const [userData, setUserData] = useState(null);
    const [planData, setPlanData] = useState(null);

    useEffect(() => {
        const getContents = async () => {
            await axios.get(
                `${process.env.REACT_APP_SERVER}/api/user/username/${params.username}`
            ).then(res => {
                if (res.status === 200) {
                    return res.data.content;
                }
                throw new Error(`Something went wrong`);
            }).then(res => {
                setUserData(res);
                axios.get(
                    `${process.env.REACT_APP_SERVER}/api/subscription-plans/all`,
                    { params: { user_id: state.id } }
                ).then(res => {
                    if (res.status === 200) {
                        return res.data.content;
                    }
                    throw new Error(`Something went wrong`);
                }).then(res => {
                    setPlanData(res);
                }).catch(error => {
                    console.log(`Could not get subscription plan data.`);
                });
            }).catch(error => {
                console.log(`Could not get user data.`);
            });
        };

        getContents();
    }, []);

    const handleSubscribe = (event) => {
        event.preventDefault();
        const id = event.target.querySelector('[name="subscription-id"').value;

        if (id) {
            axios.post(
                `${process.env.REACT_APP_SERVER}/api/subscriptions/create`, {subscription_plan_id: id}
            ).then(res =>  {
                if (res.status == 200) {
                    return res.data;
                }
                throw new Error(`Something went wrong`);
            }).then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            });
        }
    };

    return (
        <>
            <div id="appCapsule">
                {userData && <>
                    <div className="section mt-4 text-center">
                        <h2>{userData.first_name} {userData.last_name}</h2>
                    </div>

                    <div class="section mt-3 text-center">
                        <div class="avatar-section">
                            <a href="#">
                                <img src="/assets/img/sample/avatar/avatar1.jpg" alt="avatar" class="imaged w100 rounded" />
                                <span class="button">
                                    <ion-icon name="camera-outline" role="img" class="md hydrated" aria-label="camera outline"></ion-icon>
                                </span>
                            </a>
                        </div>
                    </div>
                    {planData && <>
                        <div className='section mt-4'>
                            {planData.map((item, idx) => {
                                return (
                                    <>
                                        <div className='card mb-2'>
                                            <div className='card-header'>
                                                {item.name}
                                            </div>
                                            <div className='card-body'>
                                                <div className='card-title'>
                                                    {item.price} {item.currency} {item.duration}
                                                </div>
                                                <p className='card-text'>
                                                    {item.description}
                                                </p>
                                            </div>
                                            <div className='card-footer'>
                                                <form onSubmit={handleSubscribe}>
                                                    <input name='subscription-id' type='hidden' value={item.id} />
                                                    <button className='btn btn-primary btn-block btn-lg' type='submit'>Subscribe</button>
                                                </form>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </>}
                </>}
            </div>
        </>
    );
}
