import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../App";
import { thousandSeparate } from "../../helper";
import PayPal from "../PayPal";
import { Logo } from "../fragments/Logo";
import CheckoutText from "./CheckoutText";

function Checkout() {
    const [searchparams, setSearchparams] = useSearchParams();
    const cart = searchparams.get('cart');
    const price = searchparams.get('price');
    const currency = searchparams.get('currency');
    const redirect = searchparams.get('redirect');
    const to = searchparams.get('to');
    const variant = searchparams.get('variant');
    const version = searchparams.get('version');
    const cartData = JSON.parse(decodeURIComponent(cart)); // Decode and parse cart data

    const { state, dispatch } = useContext(AuthContext);

    const [pageData, setPageData] = useState({
        isSubmitting: false,
        errorMessage: null,
    });
    const [topUpData, setTopUpData] = useState({
        required: false,
        rate: 0,
        minimum: 0,
        amount: 0,
    });
    const [topUpSuccess, setTopUpSuccess] = useState(false);
    const [confirmedTopUp, setConfirmedTopUp] = useState(false);
    const [showOrderDetails, setShowOrderDetails] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (topUpSuccess) {
            setPageData({
                ...pageData,
                isSubmitting: false,
                errorMessage: `Millix added successfully! Please proceed to complete your order.`,
            });
        }
    }, [topUpSuccess]);

    const handleInputChange = event => {
        setPageData({
            ...pageData,
            [event.target.name]: event.target.value,
        });
    };

    const data = {
        origin: window.origin,
        url: window.location.href,
        target: 'checkout_window',
        event: 'checkout',
        success: false,
        token: '',
        order: {},
        timestamp: Date.now(),
    };

    const handleCheckoutLogin = event => {
        event.preventDefault();

        setPageData({
            ...pageData,
            isSubmitting: true,
            errorMessage: null,
        });

        axios.post(`${process.env.REACT_APP_SERVER}/api/user/login`, { email: pageData.email, password: pageData.password }).then(res => {
            if (res.status === 200) {
                return res.data;
            }
            throw res;
        }).then(res => {
            dispatch({
                type: 'LOGIN',
                payload: res,
            });
            navigate(`/checkout?cart=${cart}&price=${price}&currency=${currency}&to=${to}&redirect=${redirect}&variant=${variant}&version=${version}`);

            setPageData({
                ...pageData,
                isSubmitting: false,
            });
        }).catch(err => {
            setPageData({
                ...pageData,
                isSubmitting: false,
                errorMessage: err.response.data.error || err.message || err.statusText,
            });
        });
    };

    const handleTopUpAmountSelection = (event) => {
        event.preventDefault();

        const value = event.target.value.replace('$', '');

        setTopUpData({
            ...topUpData,
            amount: value,
        });

        setConfirmedTopUp(true);

        setPageData({
            ...pageData,
            isSubmitting: false,
            errorMessage: `Add ${thousandSeparate(parseInt(value / topUpData.rate))} (mlx) to your pagado account for $${value}.`,
        });
    };

    const handleCheckout = async (event) => {
        setPageData({
            ...pageData,
            isSubmitting: true
        });

        if (!price || !currency || !to) {
            navigate('/');
            // TODO: close window and return
        }

        await axios.post(`${process.env.REACT_APP_SERVER}/api/pagado/checkout`, { cart: cart, price: price, currency: currency, to: to }).then(res => {
            if (res.status === 200) {
                return res.data;
            }
            throw res;
        }).then(res => {
            setPageData({
                ...pageData,
                isSubmitting: false,
                errorMessage: res.message,
            });

            data.success = res.success;
            data.token = res.token;
            data.order = res.order;

            window.opener.postMessage(JSON.stringify(data), '*');
        }).catch(err => {
            console.log(err);

            setPageData({
                ...pageData,
                isSubmitting: false,
                errorMessage: `${err.response?.data.message || err.response?.data || err}`,
            });

            if (Object.keys(err.response).length) {
                const data = err.response.data;

                if (data.success == false && data.code == 4001) {
                    const checkoutData = data.data;
                    const minimumAmount = roundToNearestFive(checkoutData.debit * checkoutData.rate);

                    setShowOrderDetails(false);

                    setTopUpData({
                        required: true,
                        rate: checkoutData.rate,
                        minimum: minimumAmount,
                        amount: minimumAmount,
                    });

                    setPageData({
                        ...pageData,
                        isSubmitting: false,
                        errorMessage: `You don’t have enough millix in pagado to complete this purchase. An additional ${thousandSeparate(checkoutData.debit)} (mlx) is needed to complete this purchase.`,
                    });
                }
            }
        });
    };

    const roundToNearestFive = (number) => {
        return Math.ceil(number / 5) * 5;
    };

    if (pageData.isSubmitting) {
        return (
            <>
                <p>In progress...</p>
            </>
        );
    }

    if (state.isAuthenticated) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col mt-4 mb-4 text-center">
                        <Logo width="25%" />
                        {showOrderDetails && <>
                            <CheckoutText cart={cartData} price={price} currency={currency} />
                        </>}
                    </div>
                </div>
                <div className="row">
                    <div className="col checkout-wrapper">
                        {pageData.errorMessage && <div className="mb-3">{pageData.errorMessage}</div>}

                        {topUpData.required && !topUpSuccess && <>
                            <form>
                                {!confirmedTopUp && <>
                                    <h4>Add millix to your pagado account with paypal:</h4>
                                    <div class="d-flex justify-content-center">
                                        <input className="btn btn-primary me-1" type="button" value={`$${topUpData.minimum}`} onClick={handleTopUpAmountSelection} />
                                        <input className="btn btn-primary me-1" type="button" value={`$${topUpData.minimum + 5}`} onClick={handleTopUpAmountSelection} />
                                        <input className="btn btn-primary" type="button" value={`$${topUpData.minimum + 10}`} onClick={handleTopUpAmountSelection} />
                                    </div>
                                </>}

                                {confirmedTopUp && <PayPal cart={{
                                    id: "",
                                    quantity: "",
                                    amount: parseInt(topUpData.amount / topUpData.rate),
                                    value: topUpData.amount,
                                    currency_code: 'USD',
                                }} setSuccess={setTopUpSuccess} />}
                            </form>
                        </>}
                        {!topUpData.required && <button type="submit" onClick={handleCheckout} className="btn btn-primary">
                            Pay now
                        </button>}

                        {topUpSuccess && <button type="submit" onClick={handleCheckout} className="btn btn-primary">
                            Complete order
                        </button>}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col mt-4 mb-4 text-center">
                    <Logo width="25%" />
                    <h2>Sign In</h2>
                </div>
            </div>
            <div className="row">
                <div className="col checkout-wrapper">
                    <form onSubmit={handleCheckoutLogin}>
                        <div className="form-group mb-3" controlId="formBasicEmail">
                            <label for="email" >Email address</label>
                            <input
                                className="form-control"
                                name="email"
                                type="email"
                                id="email"
                                placeholder="Enter email"
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group mb-3" controlId="formBasicPassword">
                            <label for="password">Password</label>
                            <input
                                className="form-control"
                                name="password"
                                type="password"
                                id="password"
                                placeholder="Password"
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        {pageData.errorMessage && <div className="mb-3">{pageData.errorMessage}</div>}

                        <button className="btn btn-primary" type="submit">
                            Login
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
