import axios from 'axios';
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NoData from '../fragments/NoData';
import { AuthContext } from '../../App';
import Footer from '../Footer';

export default function SingleSubscriptionPlan() {
    const { state } = useContext(AuthContext);
    const initialState = {
        isSubmitting: false,
        message: null,
    };
    const params = useParams();
    const [plan, setPlan] = useState();
    const [data, setData] = useState(initialState);
    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER}/api/subscription-plans`, { id: params.id })
            .then(res => {
                setPlan(res.data.content);
                setIsAuthorized(state.id === res.data.content.provider_id);
            }).catch(err => {
                console.log(err);
            });
    }, []);

    const handleDelete = (e) => {
        e.preventDefault();

        setData({
            ...data,
            isSubmitting: true,
        });

        axios.post(`${process.env.REACT_APP_SERVER}/api/subscription-plans/delete`, { id: params.id })
            .then(res => {
                setData({
                    ...data,
                    isSubmitting: false,
                    message: res.data.message,
                });
            })
            .catch(err => {
                setData({
                    ...data,
                    isSubmitting: false,
                    message: err.response.data.message,
                });
            });
    };

    const handleSubscribe = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>Subscription plan</h1>
                    <h4>Subscription plan details</h4>
                </div>
                <div className="section mb-5 p-2">
                    {plan ? <>
                        <div className="card">
                            <div className='card-header'>{plan.name}</div>
                            <div className='card-body'>
                                <h5 className='card-title'>{plan.price} {plan.currency}</h5>
                                <p className='card-text'>{plan.description}</p>
                            </div>
                            <div className='card-footer'>{plan.duration}</div>
                        </div>

                        {data.message && <div className="mb-3">{data.message}</div>}

                        {isAuthorized ? <form onSubmit={handleDelete}>
                            <div class="form-group transparent mt-5">
                                <Link to='edit' className="btn btn-primary mb-1 btn-block btn-lg">Edit</Link>
                                <button className="btn btn-danger mb-1 btn-block btn-lg">Delete</button>
                            </div>
                        </form> : <form onSubmit={handleSubscribe}>
                            <div class="form-group transparent mt-5">
                                <button className="btn btn-primary mb-1 btn-block btn-lg">Subscribe</button>
                            </div>
                        </form>}
                    </> : <NoData />}
                </div>
            </div>
            <Footer />
        </>
    );
}
