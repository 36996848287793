import React, { useContext, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../App";
import Navigation from "../Navigation";

export default function PasswordResetVerify() {
    const [searchparams] = useSearchParams();
    const token = searchparams.get('t');

    const { state } = useContext(AuthContext);
    const initialState = {
        password: '',
        password1: '',
        isSubmitting: false,
        errorMessage: null,
    };
    const [data, setData] = useState(initialState);

    const handleInputChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null,
        });

        if (!token) {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: `Invalid URL.`
            });
            return;
        }

        if (!data.password || !data.password1) {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: `All fields are required.`
            });
            return;
        }

        if (data.password !== data.password1) {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: `Both password must match.`
            });
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_SERVER}/api/user/password/reset-verify/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: token,
                password: data.password,
                password1: data.password1
            }),
        });

        const responseData = await response.json();

        if (response.status !== 200) {
            console.log(responseData);
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: responseData.error || "Something went wrong. Please try again.",
            });
        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: "Password updated successfully!",
            });

            // TODO: test this
            // setTimeout(() => {
            //     navigate('/login');
            // }, 1000);
        }
    }

    if (state.isAuthenticated) {
        return (
            <Navigate to="/account" />
        );
    }

    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div class="section mt-4 text-center">
                    <h1>Reset password</h1>
                    <h4>Enter new password</h4>
                </div>
                <div class="section mb-5 p-2">
                    <form onSubmit={handleFormSubmit}>
                        <div class="card">
                            <div class="card-body pb-1">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="password">Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            class="form-control"
                                            id="password"
                                            placeholder=""
                                            value={data.password}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                    <div class="form-group basic">
                                        <div class="input-wrapper">
                                            <label class="label" for="password1">Confirm password</label>
                                            <input
                                                type="password"
                                                name="password1"
                                                class="form-control"
                                                id="password1"
                                                placeholder=""
                                                value={data.password1}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <i class="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {data.errorMessage && <div className="mb-3">{data.errorMessage}</div>}

                        <div class="form-button-group  transparent">
                            <button type="submit" class="btn btn-primary btn-block btn-lg">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
