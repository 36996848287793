import axios from 'axios';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import NoData from '../fragments/NoData';
import Footer from '../Footer';

export default function SubscriptionPlans() {
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER}/api/subscription-plans/all`)
            .then(res => {
                setPlans(res.data.content);
            }).catch(err => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>Subscription plans</h1>
                    <h4>Your subscription plans list</h4>
                </div>
                <div className="section mb-5 p-2">
                    <div class="card">
                        {plans.length > 0 ? <>
                            <ul class="listview flush transparent simple-listview">
                                {plans.map((v, i) => {
                                    return <li key={i}><Link to={`../${v.id}`}>{v.name}</Link></li>;
                                })}
                            </ul>
                        </> : <NoData />}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
