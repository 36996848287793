import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../App';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from "react-i18next";
import axios from 'axios';

function Navigation() {
    const { state } = useContext(AuthContext);
    const [user, setUser] = useState({});
    const [darkMode, setDarkMode] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const mode = localStorage.getItem("FinappDarkmode");
        if (mode === '1') {
            setDarkMode(true);
        }

        if (state.id) {
            axios.post(
                `${process.env.REACT_APP_SERVER}/api/user/get/${state.id}`
            ).then(
                res => {
                    if (res.status !== 200) {
                        return;
                    }
                    setUser(res.data);
                }
            );
        }
    }, []);

    const handleDarkMode = () => {
        if (!darkMode) {
            localStorage.setItem("FinappDarkmode", "1");
            document.body.classList.add("dark-mode");
        } else {
            localStorage.setItem("FinappDarkmode", "0");
            document.body.classList.remove("dark-mode");
        }
        setDarkMode(!darkMode);
    }

    if (state.isAuthenticated === false) {
        return (
            <div className='top-navigation'>
                <nav className='navbar navbar-expand-lg navbar-dark bg-primary'>
                    <div className='container'>
                        <a href='/' className='navbar-brand'>
                            <img src={process.env.PUBLIC_URL + '/pagado_icon.png'} width='30' height='30' alt='Pagado Logo' />
                            Pagado
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarContent">
                            <div className="me-auto navbar-nav">
                                <a className="nav-link" href="/register">{t('header.register')}</a>
                                <a className="nav-link" href="/login">{t('header.sign_in')}</a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }

    return (
        <div className='top-navigation'>
            <nav className='navbar navbar-expand-lg navbar-dark bg-primary'>
                <div className='container'>
                    <a href='/account' className='navbar-brand'>
                        <img src={process.env.PUBLIC_URL + '/pagado_icon.png'} width='30' height='30' alt='Pagado Logo' />
                        Pagado
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarContent">
                        <div className="me-auto navbar-nav">
                            <a className="nav-link" href="/millix/send">{t('header.send')}</a>
                            <a className="nav-link" href="/millix/buy">{t('header.buy')}</a>
                            <a className="nav-link" href="/millix/sell">{t('header.sell')}</a>
                        </div>

                        <div className='op-wrapper'>
                            <div className="dropdown me-4">
                                <div className='profile-img-wrapper dropdown-toggle' data-bs-toggle="dropdown">
                                    <img className='profile-img' src="/images/pagado_icon_xs.png" alt="" />
                                </div>
                                <div className="dropdown-menu">
                                    <span className="dropdown-item">{Object.keys(user).length > 0 && <strong>{`${user.username}`}</strong>}</span>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="/profile">{t('header.profile')}</a>
                                    <a className="dropdown-item" href="/account">{t('header.account')}</a>
                                    <a className="dropdown-item" href="/api-direct">{t('header.api_direct')}</a>
                                    <a className="dropdown-item" href="/support">{t('header.support')}</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="/logout">{t('header.log_out')}</a>
                                </div>
                            </div>

                            <div className="theme-switch form-inline form-check form-switch my-2">
                                <input onChange={handleDarkMode} className="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch" checked={darkMode} />
                                <label className="form-check-label" for="darkmodeSwitch"></label>
                            </div>

                            <LanguageSwitcher />
                        </div>

                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navigation;
