import * as bootstrap from 'bootstrap';
import { useEffect, useState } from "react";
import * as hpr from "../../helper.js";
import Footer from "../Footer.js";
import PayPal from "../PayPal.js";

export default function BuyMillix() {
    const [selected, setSelected] = useState("USD");
    const [currentRate, setCurrentRate] = useState(null);
    const [showPayPal, setShowPayPal] = useState(false);
    const [input, setInput] = useState(0);
    const [shouldUpdateCurrentRate, setShouldUpdateCurrentRate] = useState(false);

    const [amount, setAmount] = useState({
        millix: 0,
        preferredCurrency: 0
    });

    const [data, setData] = useState({
        isUpdating: false,
        errorMessage: null,
    });

    const updateAmounts = (selected, value) => {
        if (selected === "MLX") {
            setAmount({ millix: value, preferredCurrency: value * currentRate });
        } else {
            setAmount({ millix: value / currentRate, preferredCurrency: value });
        }
    }

    const handleInputChange = event => {
        event.preventDefault();

        const value = event.target.value;

        setInput(value);
        updateAmounts(selected, value);
    };

    const handleOnblur = event => {
        event.preventDefault();

        const value = event.target.value;

        setInput(value);
        updateAmounts(selected, value);
    };

    const handleCurrencyChange = event => {
        event.preventDefault();
        setSelected(event.target.value);

        if (selected === "MLX") {
            setAmount({ millix: input / currentRate, preferredCurrency: input });
        } else {
            setAmount({ millix: input, preferredCurrency: input * currentRate });
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const modal = new bootstrap.Modal(document.getElementById('actionSheetAlertError1'), {});

        if (currentRate === null) {
            setData({
                ...data,
                errorMessage: "Unable to get current exchange rate. Please try later."
            });
            modal.show();
            return;
        }

        if (5 > amount.preferredCurrency || 500 < amount.preferredCurrency) {
            setData({
                ...data,
                isUpdating: false,
                errorMessage: "Purchase amount must be between 5 USD - 500 USD"
            });
            modal.show();
            return;
        }

        const response = await fetch(`/api/pagado/reserve-balance`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status !== 200) {
            setData({
                ...data,
                isUpdating: false,
                errorMessage: "Failed to get reserve balance. Please try later."
            });
            modal.show();
            return;
        }
        const balance = await response.json();

        if (balance < 500 / currentRate) {
            setData({
                ...data,
                isUpdating: false,
                errorMessage: "Currently unavailable. Please try later."
            });
            modal.show();
            return;
        }
        setShowPayPal(true);
        setShouldUpdateCurrentRate(!shouldUpdateCurrentRate);
    };

    useEffect(() => {
        const checkCurrentRate = async () => {
            setData({
                ...data,
                isUpdating: true
            });

            const response = await fetch(`/api/millix/current-rate`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const rate = await response.json();

            if (response.status !== 200) {
                setData({
                    ...data,
                    isUpdating: false,
                });
                return;
            }

            setCurrentRate(rate);
        };

        checkCurrentRate()
            .then(res => {
                setData({
                    ...data,
                    isUpdating: false,
                });
            });
    }, [showPayPal, shouldUpdateCurrentRate]);

    return (
        <>
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>Buy millix</h1>
                    <h4>Purchase millix with PayPal</h4>
                </div>

                {!data.isUpdating ?
                    <>
                        {!showPayPal ? <form onSubmit={handleFormSubmit}>
                            <div className="section text-center mt-4">
                                <div className="row">
                                    <div className="col">
                                        <h3 className="text-secondary">1 USD = {hpr.renderFormatMillix(1 / currentRate)} MLX</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="section mt-2">
                                <div className="row">
                                    <div className="col">
                                        <div className="stat-box grid-center">
                                            <div className="title">Exchange amount</div>
                                            <div className="value">{
                                                selected === "MLX" ? <strong> {hpr.renderFormatCurrency(amount.preferredCurrency)} USD</strong> : <strong> {hpr.renderFormatMillix(amount.millix)} MLX</strong>
                                            }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="section mt-2">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group basic p-0">
                                            <div className="exchange-heading">
                                                <label className="group-label" for="amount">Purchase amount</label>
                                                <div className="exchange-wallet-info">
                                                </div>
                                            </div>
                                            <div className="exchange-group">
                                                <div className="input-col">
                                                    <input onChange={handleInputChange} onBlur={handleOnblur} type="number"
                                                        className="form-control form-control-lg pe-0 border-0"
                                                        id="amount" placeholder="0"
                                                    />
                                                </div>
                                                <div className="select-col">
                                                    <select onChange={handleCurrencyChange} className="form-select form-select-lg currency">
                                                        <option value="USD" selected>USD</option>
                                                        <option value="MLX">MLX</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="section mt-2">
                                <div className="row fontsize-caption">
                                    <div className="col">
                                        <p style={{ lineHeight: 1.5 }}>
                                            Minimum amount : <strong>5 USD</strong> <br />
                                            Maximum amount : <strong>500 USD</strong>
                                        </p>
                                    </div>
                                    <div className="col">
                                    </div>
                                </div>
                            </div>

                            <div className="form-group transparent mt-5">
                                <button type="submit" className="btn btn-primary btn-block btn-lg">
                                    Continue
                                </button>
                            </div>
                        </form> : <div className="section mt-4">
                            <div className="row">
                                <div className="col-xl"></div>
                                <div className="col-xl"><div className="card paypal">
                                    <div className="card-header text-secondary">Purchase {hpr.renderFormatMillix(amount.millix)} MLX for {hpr.renderFormatCurrency(amount.preferredCurrency)} USD</div>
                                    <div className="card-body">
                                        <PayPal cart={{
                                            id: "",
                                            quantity: "",
                                            amount: parseInt(amount.millix),
                                            value: amount.preferredCurrency,
                                            currency_code: 'USD',
                                        }} />
                                    </div>
                                </div></div>
                                <div className="col-xl"></div>
                            </div>
                        </div>}

                    </> : <div className="section mt-4">
                        <div className="card notice-icon">
                            <div className="card-body">Getting updated data. Please wait...</div>
                        </div>
                    </div>
                }
            </div>

            <div class="modal fade action-sheet" id="actionSheetAlertError1" data-bs-backdrop="static" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="action-sheet-content">
                                <div class="iconbox text-danger">
                                    <ion-icon name="alert-circle" role="img" class="md hydrated" aria-label="alert circle"></ion-icon>
                                </div>
                                <div class="text-center p-2">
                                    <h3>Error</h3>
                                    <p>{data.errorMessage}</p>
                                </div>
                                <a href="#" class="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal">Ok</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
