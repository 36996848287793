export default function NotFoundPage() {
    return (
        <>
            <div className="card">
                <div className='card-body'>
                <h5 className='card-title'>404 - Not found</h5>
                </div>
            </div>
        </>
    );
}
