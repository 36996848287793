import axios from "axios";
import React, { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";
import Navigation from "../Navigation";

function Login() {
    const { state, dispatch } = useContext(AuthContext);
    const initialState = {
        email: '',
        password: '',
        isSubmitting: false,
        errorMessage: null,
    };
    const [data, setData] = useState(initialState);
    const navigate = useNavigate();

    const handleInputChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleFormSubmit = event => {
        event.preventDefault();

        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null,
        });

        axios.post(`${process.env.REACT_APP_SERVER}/api/user/login`, { email: data.email, password: data.password })
            .then(res => {
                if (res.status === 200) {
                    return res.data;
                }
                throw res;
            })
            .then(res => {
                dispatch({
                    type: 'LOGIN',
                    payload: res,
                });
                navigate('/account');
            })
            .catch(err => {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: err.message || err.statusText,
                });
            });
    };

    if (state.isAuthenticated) {
        return (
            <Navigate to="/account" />
        );
    }

    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div class="section mt-4 text-center">
                    <h1>Sign in</h1>
                    <h4>Fill the form to sign in</h4>
                </div>
                <div class="section mb-5 p-2">
                    <form onSubmit={handleFormSubmit}>
                        <div class="card">
                            <div class="card-body pb-1">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="email">E-mail</label>
                                        <input
                                            type="email"
                                            name="email"
                                            class="form-control"
                                            id="email"
                                            placeholder="Your e-mail"
                                            value={data.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="password">Password</label>
                                        <input
                                            type="password"
                                            name="password"
                                            class="form-control"
                                            id="password"
                                            autocomplete="off"
                                            placeholder="Your password"
                                            value={data.password}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-links mt-2">
                            <div>
                                <a href="/register">Register Now</a>
                            </div>
                            <div><a href="/password-reset" class="text-muted">Forgot Password?</a></div>
                        </div>

                        {data.errorMessage && <div className="mb-3">{data.errorMessage}</div>}

                        <div class="form-group transparent mt-5">
                            <button type="submit" class="btn btn-primary btn-block btn-lg">Sign in</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Login;
