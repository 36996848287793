import { useEffect } from "react";

function CheckoutButtons() {

    useEffect(() => {
        // window.addEventListener('message', function (e) {
        //     // console.log('message received from parent: ', e);
        // });
        document.body.classList.add('transparent');
    }, []);

    const handleCheckoutButtonClick = event => {
        const data = {
            origin: window.origin,
            url: window.location.href,
            target: 'checkout_button',
            event: 'click',
            timestamp: Date.now(),
        };
        window.parent.postMessage(JSON.stringify(data), '*');
        // console.log(window.parent);
    }

    return (
        <div className="container" fluid={true}>
            <div className="row">
                <div className="col checkout-buttons-wrapper">
                    <button onClick={handleCheckoutButtonClick} className="btn btn-primary w-100">
                        Pagado
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CheckoutButtons;

