import { thousandSeparate } from '../../helper.js';

function CheckoutText({ cart, price, currency }) {
    return (
        <>
            <div className='checkout-details'>
                {cart.length && <>
                    <table className='table mb-2'>
                        <thead>
                            <tr>
                                <th scope='col'>item</th>
                                <th scope='col'>price</th>
                                <th scope='col'>qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cart.map((elm, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{elm.name}</td>
                                        <td>{thousandSeparate(elm.price)}</td>
                                        <td>{elm.quantity}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>}
               <p>Total {thousandSeparate(price)} {currency}.</p>
            </div>
        </>
    );
}

export default CheckoutText;
