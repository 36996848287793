import { Navigate, Outlet } from "react-router-dom";
import Navigation from "../Navigation";

export default function ProtectedRoute({ state, redirectionPath = '/login', children }) {
    if (!state || (!state.loading && !state.isAuthenticated)) {
        return <Navigate to={redirectionPath} replace />;
    } else if (!state.loading && state.isAuthenticated) {
        return (
            <>
                <Navigation />
                {children ? children : <Outlet />}
            </>
        );
    }
    return <p>Loading...</p>;
}
