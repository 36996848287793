import Footer from "./Footer";
import Navigation from "./Navigation";

function Faqs() {
    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>FAQ's</h1>
                    <h4>Have any questions?</h4>
                </div>
            </div>
            <Footer />
        </>

    );
}

export default Faqs;
