import axios from 'axios';
import React, { createContext, useEffect, useReducer } from 'react';
import './App.css';
import Router from './components/route/Router';

export const AuthContext = createContext();

const initialState = {
    isAuthenticated: false,
    id: null,
    email: null,
    loading: true,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isAuthenticated: true,
                id: action.payload.id,
                email: action.payload.email,
                loading: false,
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                id: null,
                email: null,
                loading: false,
            };
        default:
            return state;
    }
};

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const verifyAuth = async () => {
            try {
                const res = await axios.post(`${process.env.REACT_APP_SERVER}/api/auth/verify`);
                if (res.status === 200) {
                    dispatch({
                        type: 'LOGIN',
                        payload: {
                            id: res.data.id,
                            email: res.data.email,
                        },
                    });
                } else {
                    throw new Error('Authentication failed');
                }
            } catch (error) {
                // console.log('Authentication error:', error);
                dispatch({ type: 'LOGOUT' });
            }
        };

        if (state.loading) {
            verifyAuth();
        }
    }, [state.loading]);

    console.log('loading...', state.loading); // Remove debug

    if (state.loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="App">
            <AuthContext.Provider value={{ state, dispatch }}>
                <Router />
            </AuthContext.Provider>
        </div>
    );
}

export default App;
