import axios from "axios";
import React, { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";
import Navigation from "../Navigation";

function Register() {
    const { state, dispatch } = useContext(AuthContext);
    const initialState = {
        userName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        isSubmitting: false,
        errorMessage: null,
    };
    const [data, setData] = useState(initialState);
    const navigate = useNavigate();

    const handleInputChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleFormSubmit = event => {
        event.preventDefault();
        let userNameRegex = /^[a-zA-Z0-9_]+$/;

        setData({
            ...data,
            isSubmitting: true,
        });

        if (
            !data.userName || !data.firstName
            || !data.lastName || !data.email
            || !data.phone || !data.password
        ) {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: "All fields are required",
            });
            return;
        }

        if (!userNameRegex.test(data.userName)) {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: "Invalid username format. Please use combination of numbers, letters and underscore with no space in between.",
            });
            return;
        }

        if (data.password !== data.confirmPassword) {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: "Both password must match",
            });
            return;
        }

        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null,
        });

        // Call registration API
        axios.post(`${process.env.REACT_APP_SERVER}/api/user/register`, {
            userName: data.userName,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            password: data.password,
        })
            .then(async res => {
                if (res.status !== 200) {
                    throw res;
                }

                axios.post(`${process.env.REACT_APP_SERVER}/api/user/login`, { email: data.email, password: data.password })
                    .then(res => {
                        if (res.status === 200) {
                            return res.data;
                        }
                        throw res;
                    })
                    .then(res => {
                        dispatch({
                            type: 'LOGIN',
                            payload: res,
                        });
                        navigate('/account');
                    })
                    .catch(err => {
                        setData({
                            ...data,
                            isSubmitting: false,
                            errorMessage: err.response?.data || err.message || err.statusText,
                        });
                    });
            })
            .catch(err => {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: err.response?.data || err.message || err.statusText,
                });
            });
    };

    if (state.isAuthenticated) {
        return (
            <Navigate to="/account" />
        );
    }

    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div class="section mt-4 text-center">
                    <h1>Register now</h1>
                    <h4>Create an account</h4>
                </div>
                <div class="section mb-5 p-2">
                    <form onSubmit={handleFormSubmit}>
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="userName">Username (Unique)</label>
                                        <input type="text" class="form-control" id="userName"
                                            value={data.userName} name="userName" placeholder="Enter username"
                                            onChange={handleInputChange} required />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="firstName">First name</label>
                                        <input type="text" class="form-control" id="firstName"
                                            value={data.firstName} name="firstName" placeholder="Enter first name"
                                            onChange={handleInputChange} required />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="lastName">Last name</label>
                                        <input type="text" class="form-control" id="lastName"
                                            value={data.lastName} name="lastName" placeholder="Enter last name"
                                            onChange={handleInputChange} required />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="email">E-mail address (Unique)</label>
                                        <input type="email" class="form-control" id="email"
                                            value={data.email} name="email" placeholder="Enter e-mail"
                                            onChange={handleInputChange} required />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="phone">Phone number (Unique)</label>
                                        <input type="tel" class="form-control" id="phone"
                                            value={data.phone} autocomplete="off" name="phone"
                                            placeholder="Enter phone number"
                                            onChange={handleInputChange} required />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="password">Password</label>
                                        <input type="password" class="form-control" id="password"
                                            value={data.password} placeholder="Enter password"
                                            name="password" onChange={handleInputChange} required />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="confirmPassword">Confirm password</label>
                                        <input type="password" class="form-control" id="confirmPassword"
                                            value={data.confirmPassword} placeholder="Confirm password" name="confirmPassword" onChange={handleInputChange} required />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="custom-control custom-checkbox mt-2 mb-1">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="registerCheckbox" />
                                        <label class="form-check-label" for="registerCheckbox">
                                            I agree <a href="#" data-bs-toggle="modal" data-bs-target="#termsModal">terms and
                                                conditions</a>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {data.errorMessage && <div className="mb-3">{data.errorMessage}</div>}

                        <div class="form-group transparent mt-5">
                            <button type="submit" class="btn btn-primary btn-block btn-lg">
                                Register
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="modal fade modalbox" id="termsModal" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Terms and Conditions</h5>
                            <a href="#" data-bs-dismiss="modal">Close</a>
                        </div>
                        <div class="modal-body">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fermentum, urna eget finibus
                                fermentum, velit metus maximus erat, nec sodales elit justo vitae sapien. Sed fermentum
                                varius erat, et dictum lorem. Cras pulvinar vestibulum purus sed hendrerit. Praesent et
                                auctor dolor. Ut sed ultrices justo. Fusce tortor erat, scelerisque sit amet diam rhoncus,
                                cursus dictum lorem. Ut vitae arcu egestas, congue nulla at, gravida purus.
                            </p>
                            <p>
                                Donec in justo urna. Fusce pretium quam sed viverra blandit. Vivamus a facilisis lectus.
                                Nunc non aliquet nulla. Aenean arcu metus, dictum tincidunt lacinia quis, efficitur vitae
                                dui. Integer id nisi sit amet leo rutrum placerat in ac tortor. Duis sed fermentum mi, ut
                                vulputate ligula.
                            </p>
                            <p>
                                Vivamus eget sodales elit, cursus scelerisque leo. Suspendisse lorem leo, sollicitudin
                                egestas interdum sit amet, sollicitudin tristique ex. Class aptent taciti sociosqu ad litora
                                torquent per conubia nostra, per inceptos himenaeos. Phasellus id ultricies eros. Praesent
                                vulputate interdum dapibus. Duis varius faucibus metus, eget sagittis purus consectetur in.
                                Praesent fringilla tristique sapien, et maximus tellus dapibus a. Quisque nec magna dapibus
                                sapien iaculis consectetur. Fusce in vehicula arcu. Aliquam erat volutpat. Class aptent
                                taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
