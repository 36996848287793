import Footer from "../Footer";

export default function SellMillix() {
    return (
        <>
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>Sell millix</h1>
                    <h4>Sell millix right from your Pagado</h4>
                </div>

                <div className="section mt-5 text-center">
                    <div className="row">
                        <div className="col">
                            <h2>Temporary unavailable</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade action-sheet" id="actionSheetAlertError1" data-bs-backdrop="static" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="action-sheet-content">
                                <div class="iconbox text-danger">
                                    <ion-icon name="alert-circle" role="img" class="md hydrated" aria-label="alert circle"></ion-icon>
                                </div>
                                <div class="text-center p-2">
                                    <h3>Error</h3>
                                    <p>{'data.errorMessage'}</p>
                                </div>
                                <a href="#" class="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal">Ok</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
