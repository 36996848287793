import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import { useContext, useState } from "react";
import { AuthContext } from "../App";

function PayPal({ cart, setSuccess }) {
    const { state } = useContext(AuthContext);

    const initialOptions = {
        'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
        'enable-funding': 'credit,card',
        'disable-funding': 'paylater',
    };

    const [operation, setOperation] = useState(
        {
            complete: false,
            success: false,
            message: null,
        });

    return (
        <> {!operation.complete && <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons style={{ shape: 'rect', layout: 'vertical' }}
                createOrder={async () => {
                    try {
                        const response = await fetch('/api/paypal/orders', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                cart: cart,
                            }),
                        });

                        const orderData = await response.json();

                        if (response.status !== 200) {
                            throw new Error(orderData.error);
                        }

                        if (orderData.id) {
                            return orderData.id;
                        } else {
                            const errorDetail = orderData?.details?.[0];
                            const errorMessage = errorDetail ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})` : JSON.stringify(orderData);

                            throw new Error(errorMessage);
                        }
                    } catch (err) {
                        setOperation({ ...operation, message: err.message });
                        const modal = new bootstrap.Modal(document.getElementById('actionSheetAlertError'), {});
                        modal.show();
                    }
                }}

                onApprove={async (data, actions) => {
                    try {
                        const response = await fetch(`/api/paypal/orders/${data.orderID}/capture`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });

                        const orderData = await response.json();
                        const errorDetail = orderData?.details?.[0];

                        if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
                            return actions.restart();
                        } else if (errorDetail) {
                            throw new Error(errorDetail.description);
                        } else {
                            await axios.post(`${process.env.REACT_APP_SERVER}/api/reserve/transfer`, {
                                id: state.id,
                                amount: cart.amount
                            }).then(res => {
                                if (res.status === 200) {
                                    return res.data;
                                }
                                throw res.data.message;
                            }).then(res => {
                                const transaction = orderData.purchase_units[0].payments.captures[0];
                                setOperation({ complete: true, success: true, message: `${transaction.status} - Transaction ID: ${transaction.id}` });

                                setSuccess(true);

                                const modal = new bootstrap.Modal(
                                    document.getElementById('actionSheetAlertSuccess'),
                                    {}
                                );
                                modal.show();
                            }).catch(err => {
                                throw err.response.data;
                            });
                        }
                    } catch (err) {
                        setOperation({ complete: true, success: false, message: err });

                        const modal = new bootstrap.Modal(document.getElementById('actionSheetAlertError'), {});
                        modal.show();
                    }
                }} />
        </PayPalScriptProvider>}

            <div className="modal fade action-sheet" id="actionSheetAlertSuccess" data-bs-backdrop="static" tabindex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                <div className="iconbox text-success">
                                    <ion-icon name="checkmark-circle" role="img" className="md hydrated" aria-label="checkmark circle"></ion-icon>
                                </div>
                                <div className="text-center p-2">
                                    <h3>Success</h3>
                                    <p>{operation.message}</p>
                                </div>
                                <button type="button" className="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal" aria-label="Close">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade action-sheet" id="actionSheetAlertError" data-bs-backdrop="static" tabindex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="action-sheet-content">
                                <div className="iconbox text-danger">
                                    <ion-icon name="alert-circle" role="img" className="md hydrated" aria-label="alert circle"></ion-icon>
                                </div>
                                <div className="text-center p-2">
                                    <h3>Error</h3>
                                    <p>{operation.message}</p>
                                </div>
                                <button type='button' className="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal" aria-label="Close">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PayPal;
