import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Footer from "../Footer";

export default function SendMillix() {
    const navigate = useNavigate();
    const initialState = {
        to: '',
        amount: '',
        fee: 0,
        isSubmitting: false,
        message: null,
    };
    const [data, setData] = useState(initialState);

    const handleInputChange = event => {
        if (event.target.name === 'amount') {
            let value = event.target.value;
            value = value.replace(/[^0-9]/g, '');
            value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            setData({
                ...data,
                [event.target.name]: value,
            });
        } else {
            setData({
                ...data,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setData({
            ...data,
            isSubmitting: true,
            message: null,
        });

        Swal.fire({
            title: 'Transaction in progress',
            text: 'Please wait...',
            imageUrl: '/assets/img/icon/128x128.png',
            imageHeight: 128,
            imageWidth: 128,
            imageAlt: 'pagado icon',
            showConfirmButton: false,
        });

        await axios.get(`${process.env.REACT_APP_SERVER}/api/millix/send`, {
            params: {
                to: data.to, amount: data.amount.replaceAll(',', ''),
                fee: data.fee
            }
        }).then(res => {
            if (res.status === 200) {
                return res.data;
            }
            throw res;
        }).then(res => {
            setData({
                ...data,
                isSubmitting: false,
                message: "Transaction successful",
            });

            Swal.fire({
                title: 'Success!',
                text: 'Transaction successful!',
                icon: 'success',
                confirmButtonText: 'Ok',
            }).then(result => {
                if (result.isConfirmed) {
                    navigate('/account');
                }
            });
        }).catch(err => {
            setData({
                ...data,
                isSubmitting: false,
                message: "Transaction failed",
            });

            Swal.fire({
                title: 'Failed',
                text: 'Transaction failed.',
                icon: 'error',
                confirmButtonText: 'Go to account',
                showCancelButton: true,
                cancelButtonText: 'Try again',
                cancelButtonColor: '#6236FF',
            }).then(result => {
                if (result.isConfirmed) {
                    navigate('/account');
                }
            });
        });
    };

    return (
        <>
            <div id="appCapsule">
                <div class="section mt-4 text-center">
                    <h1>Send millix</h1>
                    <h4>Send millix to others</h4>
                </div>
                <div class="section mb-5 p-2">
                    <form onSubmit={handleFormSubmit}>
                        <div class="card">
                            <div class="card-body pb-1">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="to">Address, username or e-mail</label>
                                        <input
                                            type="text"
                                            name="to"
                                            placeholder="Enter millix address, pagado username or pagado email"
                                            class="form-control"
                                            id="email"
                                            value={data.to}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <small id="toHelpBlock" className="form-text text-muted">
                                            (Sending by username and email is only available to Pagado users)
                                        </small>
                                    </div>
                                </div>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="amount">Amount</label>
                                        <input
                                            type="text"
                                            name="amount"
                                            class="form-control"
                                            id="amount"
                                            value={data.amount}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="fee">Fee</label>
                                        <input
                                            type="number"
                                            name="fee"
                                            class="form-control"
                                            id="fee"
                                            placeholder="Enter amount"
                                            value={data.fee || 1000}
                                            onChange={handleInputChange}
                                            required
                                            disabled
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {data.message && <div className="mb-3">{data.message}</div>}

                        <div class="form-group transparent mt-5">
                            <button type="submit" class="btn btn-primary btn-block btn-lg">Send</button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}
