import i18n from '../i18n';
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
    const { t } = useTranslation();
    const languages = {
        en: { nativeName: 'English' },
        es: { nativeName: 'Español' }
    };

    return (
        <div class="dropdown">
            <button class="btn text-white dropdown-toggle" type="button" data-bs-toggle="dropdown">
                {t('header.language')}
            </button>
            <div class="dropdown-menu dropdown-menu-end">
                {Object.keys(languages).map((lng) => (
                    <button class="dropdown-item" key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                        {languages[lng].nativeName}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default LanguageSwitcher;
