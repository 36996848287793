import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    header: {
                        register: 'Register',
                        sign_in: 'Sign in',
                        profile: 'Profile',
                        account: 'Account',
                        send: 'Send',
                        buy: 'Buy',
                        sell: 'Sell',
                        api_direct: 'API Direct',
                        support: 'Support',
                        log_out: 'Logout',
                        language: "EN"
                    },
                    footer: {
                        home: 'Home',
                        terms_of_service: 'Terms of service',
                        privacy_policy: 'Privacy policy',
                        contact: 'Contact',
                        faqs: 'FAQs',
                        about: 'About',
                        copyright: '',
                    },
                    page: {
                        index: {
                            body: {
                                title: 'Welcome!',
                                subtitle: 'Payments made easy. Powered by Millix.',
                                buttons: {
                                    register: 'Register',
                                    sign_in: 'Sign in',
                                },
                            },
                        },
                    },
                }
            },
            es: {
                translation: {
                    header: {
                        register: 'Registro',
                        sign_in: 'Iniciar sesión',
                        profile: 'Perfil',
                        account: 'Cuenta',
                        send: 'Enviar',
                        buy: 'Comprar',
                        sell: 'Vender',
                        api_direct: 'API Directo',
                        support: 'Apoyo',
                        log_out: 'Cerrar sesión',
                        language: "ES"
                    },
                    footer: {
                        home: 'Hogar',
                        terms_of_service: 'Términos de servicio',
                        privacy_policy: 'Política de privacidad',
                        contact: 'Contacto',
                        faqs: 'FAQs',
                        about: 'Acerca de',
                        copyright: '',
                    },
                    page: {
                        index: {
                            body: {
                                title: '¡Bienvenido!',
                                subtitle: 'Pagos simplificados. Desarrollado por Millix.',
                                buttons: {
                                    register: 'Registro',
                                    sign_in: 'Iniciar sesión',
                                },
                            },
                        },
                    },
                }
            }
        }
    });

export default i18n;
