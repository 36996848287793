import axios from "axios";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";

function Logout() {
    const { state, dispatch } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (state.isAuthenticated) {
            axios.post(`${process.env.REACT_APP_SERVER}/api/user/logout`)
                .then(res => {
                    if (res.status === 200) {
                        dispatch({
                            type: 'LOGOUT',
                        });
                        navigate('/');
                    }
                });
        }
    }, [state]);

    return (
        <></>
    );
}

export default Logout;
