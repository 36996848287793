import * as bootstrap from 'bootstrap';
import { useEffect, useState } from "react";
import Navigation from "./Navigation";
import Footer from './Footer';

function Contact() {
    const initialState = {
        from: '',
        subject: '',
        text: '',
        isSubmitting: false,
        errorMessage: "",
    };
    const [data, setData] = useState(initialState);

    useEffect(() => {
        const recaptchaScript = document.getElementById('recaptcha-script');
        const script = document.createElement('script');

        if (!recaptchaScript) {
            script.src = `https://www.google.com/recaptcha/api.js`;
            script.async = true;
            script.defer = true;
            script.id = 'recaptcha-script';
            document.body.appendChild(script);
        }

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const operationDefault = {
        success: false,
        message: '',
    };
    const [operation, setOperation] = useState(operationDefault);

    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setData({
            ...data,
            isSubmitting: true,
            errorMessage: "",
        });

        const recaptchaResponse = window.grecaptcha.getResponse();

        if (!recaptchaResponse) {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: "Verify reCAPTCHA",
            });
            return;
        }

        const recapResponse = await fetch(`/api/auth/recaptcha-verify`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                response: recaptchaResponse
            })
        });

        const recapVerifyResp = await recapResponse.json();

        if (!recapVerifyResp.success) {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: "reCAPTCHA verification failed",
            });
            console.log(recapVerifyResp.message);
            return;
        }

        const response = await fetch(`/api/contact/send-email`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    from: data.from,
                    subject: data.subject,
                    text: data.text
                })
            }
        );

        const resData = await response.json();

        if (response.status !== 200) {

            setOperation({
                success: false,
                message: resData.message,
            });

            const modal = new bootstrap.Modal(document.getElementById('actionSheetAlertError'), {});
            modal.show();
            return;
        }

        setOperation({
            success: true,
            message: await resData.message,
        });

        const modal = new bootstrap.Modal(document.getElementById('actionSheetAlertSuccess'), {});
        modal.show();
    };

    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div class="section mt-4 text-center">
                    <h1>Contact Us</h1>
                </div>
                <div class="section mb-5 p-2">
                    <form onSubmit={handleFormSubmit}>
                        <div class="card">
                            <div class="card-body pb-1">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="from">Your email</label>
                                        <input
                                            type="email"
                                            name="from"
                                            class="form-control"
                                            placeholder="Enter your email"
                                            id="from"
                                            autocomplete="off"
                                            value={data.from}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="subject">Subject</label>
                                        <input
                                            type="text"
                                            name="subject"
                                            class="form-control"
                                            id="subject"
                                            placeholder='Enter the subject'
                                            autocomplete="off"
                                            value={data.subject}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="text">Details</label>
                                        <textarea
                                            class="form-control"
                                            name="text"
                                            placeholder='Enter description'
                                            id="text"
                                            autocomplete="off"
                                            value={data.text}
                                            rows="4"
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>

                            <div className='g-recaptcha mt-2 mb-3' data-sitekey={process.env.REACT_APP_RECAPTCHA_KEY}></div>
                        </div>

                        {data.errorMessage && <div className="mb-3">{data.errorMessage}</div>}

                        <div class="form-group transparent mt-5">
                            <button type="submit" class="btn btn-primary btn-block btn-lg">Send</button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="modal fade action-sheet" id="actionSheetAlertSuccess" data-bs-backdrop="static" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="action-sheet-content">
                                <div class="iconbox text-success">
                                    <ion-icon name="checkmark-circle" role="img" class="md hydrated" aria-label="checkmark circle"></ion-icon>
                                </div>
                                <div class="text-center p-2">
                                    <h3>Success</h3>
                                    <p>{operation.message}</p>
                                </div>
                                <a href="#" class="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal">Ok</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade action-sheet" id="actionSheetAlertError" data-bs-backdrop="static" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="action-sheet-content">
                                <div class="iconbox text-danger">
                                    <ion-icon name="alert-circle" role="img" class="md hydrated" aria-label="alert circle"></ion-icon>
                                </div>
                                <div class="text-center p-2">
                                    <h3>Error</h3>
                                    <p>{operation.message}</p>
                                </div>
                                <a href="#" class="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal">Try again</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Contact;
