import axios from 'axios';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from '../Footer';

export default function EditSubscriptionPlan() {
    const params = useParams();
    const initialState = {
        name: '',
        description: '',
        price: '',
        currency: '',
        duration: '',
        isSubmitting: false,
        message: '',
    };
    const [data, setData] = useState(initialState);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER}/api/subscription-plans`, { id: params.id })
            .then(res => {
                setData({
                    ...data,
                    name: res.data.content.name,
                    description: res.data.content.description,
                    price: res.data.content.price,
                    currency: res.data.content.currency,
                    duration: res.data.content.duration,
                    isSubmitting: false,
                });
            }).catch(err => {
                setData({
                    ...data,
                    message: 'Data retrieval failed',
                });
                console.log(err);
            });
    }, []);

    const handleInputChange = (e) => {
        setHasChanged(true);

        if (e.target.name === 'price') {
            let value = e.target.value;
            value = value.replace(/[^0-9]/g, '');
            value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            setData({
                ...data,
                [e.target.name]: value,
            });
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        setHasChanged(false);

        axios.post(`${process.env.REACT_APP_SERVER}/api/subscription-plans/update`, {
            id: params.id,
            name: data.name,
            description: data.description,
            price: data.price,
            currency: data.currency,
            duration: data.duration,
        }).then(res => {
            setData({
                ...data,
                isSubmitting: false,
                message: res.data.message,
            });
        }).catch(err => {
            setData({
                ...data,
                isSubmitting: false,
                message: err.response.data.message,
            });
        });
    };

    return (
        <>
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>Subscriptions</h1>
                    <h4>Create new subscription plan</h4>
                </div>
                <div className="section mb-5 p-2">
                    <form onSubmit={handleFormSubmit}>
                        <div className="card-body pb-1">
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" for="amount">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        id="name"
                                        value={data.name}
                                        onChange={handleInputChange}
                                        required />
                                </div>
                            </div>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" for="amount">Description</label>
                                    <textarea
                                        name="description"
                                        className="form-control"
                                        id="description"
                                        value={data.description}
                                        onChange={handleInputChange}
                                        required />
                                </div>
                            </div>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label" for="amount">Price</label>
                                    <input
                                        type="text"
                                        name="price"
                                        className="form-control"
                                        id="price"
                                        value={data.price}
                                        onChange={handleInputChange}
                                        required />
                                </div>
                            </div>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label mb-2" for="currency">Currency</label>
                                    <select
                                        id="currency"
                                        name="currency"
                                        className="form-select form-select-sm"
                                        defaultValue=''
                                        value={data.currency}
                                        onChange={handleInputChange}
                                        aria-label=".form-select-sm"
                                        required>
                                        <option value="">Select a currency</option>
                                        <option value="mlx">Millix</option>
                                        <option value="none" disabled>Currently unavailable...</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group basic">
                                <div className="input-wrapper">
                                    <label className="label mb-2" for="duration">Duration</label>
                                    <select
                                        id="duration"
                                        name="duration"
                                        className="form-select form-select-sm"
                                        defaultValue=''
                                        value={data.duration}
                                        onChange={handleInputChange}
                                        aria-label=".form-select-sm"
                                        required>
                                        <option value="" disabled>Select a duration</option>
                                        <option value="weekly" disabled>Weekly - Currently unavailable...</option>
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly" disabled>Yearly - Currently unavailable...</option>
                                    </select>
                                </div>
                            </div>

                            {data.message && <div className="mb-3">{data.message}</div>}

                            <div class="form-group transparent mt-5">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" disabled={hasChanged ? '' : 'disabled'}>Update</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}
