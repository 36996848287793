import Footer from "./Footer";
import Navigation from "./Navigation";

function TermsOfService() {
    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>Terms of Service</h1>
                    <h4>Learn about terms of services</h4>
                </div>

                <div className="container mt-4">
                    <div className="row">
                        <div className="col">
                            <h3 className="mt-4">Agreement between User and <a href="https://pagado.io">pagado.io</a></h3>
                            <p>Welcome to <a href="https://pagado.io">pagado.io</a>. The <a href="https://pagado.io">pagado.io</a> website (the “Site”) is comprised of various web pages operated by pagado (“pagado”). <a href="https://pagado.io">pagado.io</a> is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the “Terms”). Your use of <a href="https://pagado.io">pagado.io</a> constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.</p>

                            <p><a href="https://pagado.io">pagado.io</a> is an E-Commerce Site.</p>

                            <p>pagado provides customers with the ability to purchase millix and millix related products, quickly and securely.</p>

                            <h3 className="mt-4">Privacy</h3>
                            <p>Your use of <a href="https://pagado.io">pagado.io</a> is subject to pagado's Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.</p>

                            <h3 className="mt-4">Electronic Communications</h3>
                            <p>Visiting <a href="https://pagado.io">pagado.io</a> or sending emails to pagado constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>

                            <h3 className="mt-4">Children Under Thirteen</h3>
                            <p>pagado does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use <a href="https://pagado.io">pagado.io</a> only with permission of a parent or guardian.</p>

                            <h3 className="mt-4">Cancellation/Refund Policy</h3>
                            <p>Customers have three (3) business days to cancel an order if the order has not been fulfilled. If the order has been fulfilled, the customer has 5 business days to request a refund. To request a refund, the customer must contact <strong>sales@pagado.io</strong> and provide their order details. From there, customers will receive instruction from <strong>sales@pagado.io</strong> on how to return the millix or millix related product(s). Once the millix or millix related product(s) are returned, pagado will issue the refund to the payment method used by the customer at the first available business day.</p>

                            <h3 className="mt-4">Links to Third Party Sites/Third Party Services</h3>
                            <p><a href="https://pagado.io">pagado.io</a> may contain links to other websites (“Linked Sites”). The Linked Sites are not under the control of pagado and pagado is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. pagado is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by pagado of the site or any association with its operators. Certain services made available via <a href="https://pagado.io">pagado.io</a> are delivered by third party sites and organizations. By using any product, service or functionality originating from the <a href="https://pagado.io">pagado.io</a> domain, you hereby acknowledge and consent that pagado may share such information and data with any third party with whom pagado has a contractual relationship to provide the requested product, service or functionality on behalf of <a href="https://pagado.io">pagado.io</a> users and customers.</p>

                            <h3 className="mt-4">No Unlawful or Prohibited Use/Intellectual Property</h3>
                            <p>You are granted a non-exclusive, non-transferable, revocable license to access and use <a href="https://pagado.io">pagado.io</a> strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to pagado that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>

                            <p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of pagado or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.</p>

                            <p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. pagado content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of pagado and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of pagado or our licensors except as expressly authorized by these Terms.</p>

                            <h3 className="mt-4">International Users</h3>
                            <p>The Service is controlled, operated and administered by pagado from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the pagado Content accessed through <a href="https://pagado.io">pagado.io</a> in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>

                            <h3 className="mt-4">Indemnification</h3>
                            <p>You agree to indemnify, defend and hold harmless pagado, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. pagado reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with pagado in asserting any available defenses.</p>

                            <h3 className="mt-4">Liability Disclaimer</h3>
                            <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. pagado AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>

                            <p>pagado AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED “AS IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND. pagado AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>

                            <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL pagado AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF pagado OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>

                            <h3 className="mt-4">Termination/Access Restriction</h3>
                            <p>pagado reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Florida and you hereby consent to the exclusive jurisdiction and venue of courts in Florida in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>

                            <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and pagado as a result of this agreement or use of the Site. pagado's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of pagado's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by pagado with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>

                            <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and pagado with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and pagado with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>

                            <h3 className="mt-4">Changes to Terms</h3>
                            <p>pagado reserves the right, in its sole discretion, to change the Terms under which pagado.io is offered. The most current version of the Terms will supersede all previous versions. pagado encourages you to periodically review the Terms to stay informed of our updates.</p>

                            <h3 className='mt-4'>Contact Us</h3>
                            <p>pagado welcomes your questions or comments regarding the Terms:</p>

                            <p>
                                pagado <br />
                                PO Box 883 <br />
                                Boynton Beach, Florida 33425
                            </p>

                            <p>
                                Email Address: <br />
                                info@pagado.io
                            </p>

                            <p>
                                Telephone number: <br />
                                5612212717
                            </p>

                            <p>Effective as of September 13, 2023</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default TermsOfService;
