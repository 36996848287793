import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../App";
import Navigation from "../Navigation";

export default function PasswordReset() {
    const { state } = useContext(AuthContext);
    const initialState = {
        email: '',
        isSubmitting: false,
        errorMessage: null,
    };
    const [data, setData] = useState(initialState);

    const handleInputChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setData({
            ...data,
            isSubmitting: true,
            errorMessage: null,
        });

        const response = await fetch('/api/user/password/reset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: data.email,
            }),
        });

        const responseData = await response.json();

        if (response.status !== 200) {
            console.log(responseData);
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: responseData.error || "Something went wrong. Please try again.",
            });
        } else {
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: "Please check your email for password reset link.",
            });
        }
    }

    if (state.isAuthenticated) {
        return (
            <Navigate to="/account" />
        );
    }

    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div class="section mt-4 text-center">
                    <h1>Reset password</h1>
                    <h4>Input your email and click reset button</h4>
                </div>
                <div class="section mb-5 p-2">
                    <form onSubmit={handleFormSubmit}>
                        <div class="card">
                            <div class="card-body pb-1">
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="email">E-mail</label>
                                        <input
                                            type="email"
                                            name="email"
                                            class="form-control"
                                            id="email"
                                            placeholder=""
                                            value={data.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {data.errorMessage && <div className="mb-3">{data.errorMessage}</div>}

                        <div class="form-button-group  transparent">
                            <button type="submit" class="btn btn-primary btn-block btn-lg">Reset</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
