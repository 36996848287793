import { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthContext } from '../../App';
import About from '../About';
import Account from '../Account';
import Contact from '../Contact';
import Faqs from '../Faqs';
import Home from '../Home';
import NotFoundPage from '../NotFoundPage';
import PrivacyPolicy from '../PrivacyPolicy';
import Profile from '../Profile';
import Support from '../Support';
import TermsOfService from '../TermsOfService';
import Login from '../auth/Login';
import Logout from '../auth/Logout';
import PasswordReset from '../auth/PasswordReset';
import PasswordResetVerify from '../auth/PasswordResetVerify';
import Register from '../auth/Register';
import Checkout from '../checkout/Checkout';
import CheckoutButtons from '../checkout/CheckoutButtons';
import BuyMillix from '../millix/BuyMillix';
import SellMillix from '../millix/SellMillix';
import SendMillix from '../millix/SendMillix';
import NewSubscriptionPlan from '../subscription-plans/NewSubscriptionPlan';
import SingleSubscriptionPlan from '../subscription-plans/SingleSubscriptionPlan';
import SubscriptionPlans from '../subscription-plans/SubscriptionPlans';
import ProtectedRoute from './ProtectedRoute';
import EditSubscriptionPlan from '../subscription-plans/EditSubscriptionPlan';
import Subscribers from '../subscription-plans/Subscribers';
import ApiDirect from '../api-direct/ApiDirect';
import RemoteProfile from '../RemoteProfile';
import WordPressPlugin from '../WordPressPlugin';

export default function Router(props) {
    const { state } = useContext(AuthContext);

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='user/:username'
                    element={<ProtectedRoute state={state} children={<RemoteProfile />} />}
                />
                <Route path='profile' element={<Profile />} />
                <Route path='account' element={<Account />} />
                <Route path='checkout' element={<Checkout />} />
                <Route path='checkout-buttons' element={<CheckoutButtons />} />
                <Route path='millix' element={<ProtectedRoute state={state} />}>
                    <Route path='send' element={<SendMillix />} />
                    <Route path='buy' element={<BuyMillix />} />
                    <Route path='sell' element={<SellMillix />} />
                </Route>
                <Route path='register' element={<Register />} />
                <Route path='login' element={<Login />} />
                <Route path='logout' element={<Logout />} />
                <Route path='password-reset' element={<PasswordReset />} />
                <Route path='password-reset-verify' element={<PasswordResetVerify />} />
                <Route path='terms-of-service' element={<TermsOfService />} />
                <Route path='privacy-policy' element={<PrivacyPolicy />} />
                <Route path='contact' element={<Contact />} />
                <Route path='faqs' element={<Faqs />} />
                <Route path='about' element={<About />} />
                <Route path='support' element={<Support />} />
                <Route path='wordpress' element={<WordPressPlugin />} />
                <Route path='subscription-plans' element={<ProtectedRoute state={state} />}>
                    <Route path='all' element={<SubscriptionPlans />} />
                    <Route path=':id' element={<SingleSubscriptionPlan />} />
                    <Route path='create' element={<NewSubscriptionPlan />} />
                    <Route path=':id/edit' element={<EditSubscriptionPlan />} />
                    <Route path=':id/delete' />
                    <Route path=':id/subscribers' element={<Subscribers />} />
                </Route>
                <Route path='subscriptions' element={<ProtectedRoute state={state} />}>
                    <Route path='all' />
                    <Route path=':id' />
                </Route>
                <Route path='api-direct' element={<ProtectedRoute state={state} children={<ApiDirect />} />} />
                <Route path='*' element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    );
}
