import { useEffect, useState } from "react";
import axios from 'axios';

export default function ApiDirect() {
    const [key, setKey] = useState();
    const initialData = {
        message: '',
        isSubmitting: false,
    };
    const [data, setData] = useState(initialData);

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_SERVER}/api/direct/key-get`)
            .then(res => {
                if (res.data.status == "success") {
                    setKey(res.data.content.api_key);
                }
            }).catch(err => {
                // console.log(err);
            });
    }, [key]);

    const onGenerate = (e) => {
        e.preventDefault();

        setData({
            ...data,
            isSubmitting: true,
        });

        axios.post(`${process.env.REACT_APP_SERVER}/api/direct/key-generate`)
            .then(res => {
                setKey('');
                setData({
                    ...data,
                    message: res.data.message
                });
            }).catch(err => {
                setData({
                    ...data,
                    isSubmitting: false,
                    message: 'Key generation failed. Please try again.',
                });
                console.log(err);
            });
    };

    const onDelete = (e) => {
        e.preventDefault();

        setData({
            ...data,
            isSubmitting: true,
        });

        axios.post(`${process.env.REACT_APP_SERVER}/api/direct/key-delete`)
            .then(res => {
                console.log(res.data);
                setKey(null);
                setData({
                    ...data,
                    message: res.data.message
                });
            }).catch(err => {
                setData({
                    ...data,
                    isSubmitting: false,
                    message: 'Key removal failed.',
                });
                console.log(err);
            });
    };

    return (
        <>
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>API Direct</h1>
                    <h4>Manage your API Direct credentials</h4>
                </div>
                <div className="section mb-5 p-2">
                    <div className="card">
                        <div className='card-header'>API key</div>
                        <div className='card-body'>
                            <h5 className='card-title'>{key ? key : 'No key available. Please generate one.'}</h5>
                        </div>
                        <div className='card-footer'>Generating a new key will invalidate your previous key and generate a new one. If you want to disable API Direct access, use "Delete" button. You can always generate new key when you need one.</div>
                    </div>

                    {data.message && <div className="mb-3">{data.message}</div>}

                    <form onSubmit={(e) => { e.preventDefault(); }}>
                        <div class="form-group transparent mt-5">
                            <button onClick={onGenerate} className="btn btn-primary mb-1 btn-block btn-lg">Generate</button>
                            <button onClick={onDelete} className="btn btn-danger mb-1 btn-block btn-lg">Delete</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
