import { faCalendarCheck, faCopy, faCreditCard, faHashtag, faRightFromBracket, faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../App';
import Navigation from './Navigation';
import Footer from './Footer';

function transactionColor(type) {
    return type == "credit" ? "#66914b" : "#934e4e";
}

function transactionSign(type) {
    return type === "credit" ? "+" : "-";
}

function transactionIcon(type) {
    return type === "credit" ? "/images/pagado_credit.svg" : "/images/pagado_debit.svg";
}

function addressExcerpt(address) {
    const addr = address.split('0a0');
    return addr[0].substring(0, 10) + '{..}' + addr[1].substring(24);
}

function Account() {
    const { state } = useContext(AuthContext);
    const [currentRate, setCurrentRate] = useState(null);
    const [stableBalance, setStableBalance] = useState(0);
    const [unstableBalance, setUnstableBalance] = useState(0);
    const [millixAddress, setMillixAddress] = useState('');
    const [trxData, setTrxData] = useState([]);
    const [pendingTrxData, setPendingTrxData] = useState([]);
    const [user, setUser] = useState({});
    const [popupData, setPopupData] = useState({});

    useEffect(() => {
        if (state.isAuthenticated) {
            axios.get(
                `${process.env.REACT_APP_SERVER}/api/millix/address`
            ).then(addrRes => {
                if (addrRes.status !== 200) {
                    setMillixAddress("Invalid address");
                    return;
                }
                setMillixAddress(addrRes.data.address);

                axios.get(
                    `${process.env.REACT_APP_SERVER}/api/millix/balance/${addrRes.data.address}`
                ).then(res => {
                    if (res.status !== 200) {
                        return;
                    }
                    const { stable, unstable } = res.data;

                    setStableBalance(stable);
                    setUnstableBalance(unstable);
                }).catch(err => {
                    console.log(err);
                });
            });

            axios.get(
                `${process.env.REACT_APP_SERVER}/api/millix/current-rate`
            ).then(res => {
                if (res.status !== 200) {
                    return;
                }
                setCurrentRate(res.data);
            }).catch(err => {
                console.log(err);
            });

            axios.get(
                `${process.env.REACT_APP_SERVER}/api/pagado/transactions`
            ).then(res => {
                if (res.status !== 200) {
                    return;
                }
                setTrxData(res.data);
            }).catch(err => {
                console.log(err);
            });

            axios.get(
                `${process.env.REACT_APP_SERVER}/api/pagado/pending-transactions`
            ).then(res => {
                if (res.status !== 200) {
                    throw res;
                }
                return res.data;
            }).then(res => {
                setPendingTrxData(res.content);
            }).catch(error => {
                if (error) {
                    console.log(error.data.error);
                }
            });

            if (state.id) {
                axios.post(
                    `${process.env.REACT_APP_SERVER}/api/user/get/${state.id}`
                ).then(
                    res => {
                        if (res.status !== 200) {
                            return;
                        }
                        setUser(res.data);
                    }
                );
            }
        }
    }, [state]);

    const handleCopy = (e) => {
        navigator.clipboard.writeText(millixAddress);
    };

    const handleSingleTransactionClick = (data, index) => {
        setPopupData(data);

        const popup = document.querySelector('.pagado-popup');
        const popupContent = document.getElementById('single-transaction-details');
        const popupBg = document.querySelector('.popup-bg');

        popup.classList.remove('pagado-hide');

        popupBg.addEventListener('click', (e) => {
            popupContent.parentElement.classList.add('pagado-hide');
        });
    };

    if (state.loading) {
        return <div>Loading...</div>
    }

    return (
        <>
            {!state.isAuthenticated && <Navigate replace to='/login' />}

            <Navigation />
            <div id="appCapsule">
                <div className="section hero full gradientSection">
                    <div className="in">
                        <h5 className="title mb-2">Current Balance</h5>
                        <h2 className="total">
                            Current: {new Intl.NumberFormat().format(stableBalance)} MLX
                            <span>
                                {currentRate && <>{Intl.NumberFormat().format(stableBalance * currentRate)} USD</>}
                            </span>
                        </h2>
                        <h2 className="total">
                            Pending: {new Intl.NumberFormat().format(unstableBalance)} MLX
                            <span>
                                {currentRate && <>{Intl.NumberFormat().format(unstableBalance * currentRate)} USD</>}
                            </span>
                        </h2>
                        {Object.keys(user).length > 0 && <h3 className='text-white'>{`${user.username}`}</h3>}
                        <h4 className="caption">
                            <span className="text-success me-1">
                                Millix address:
                            </span>
                            {millixAddress}
                            <FontAwesomeIcon className='copy-action ms-1' onClick={handleCopy} icon={faCopy} />
                        </h4>
                    </div>
                </div>

                {pendingTrxData.length > 0 ? <>
                    <div className='section mt-4'>
                        <div className='section-heading'>
                            <h2 className='title'>Pending transactions</h2>
                        </div>

                        <div className='pending-transaction-list-wrapper'>
                            <div className='table-responsive pagado-hide-mobile'>
                                <div className='card'>
                                    <table className='transaction-table table table-striped'>
                                        <thead>
                                            <tr>
                                                <th scope='col'>Date</th>
                                                <th scope='col'>From / To</th>
                                                <th className='text-end' scope='col'>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pendingTrxData.map((data, index) => {
                                                const tType = data.transaction_type;
                                                const address = tType === "credit" ? data.from_address : data.to_address;
                                                const user = tType === "credit" ? data.from_user : data.to_user;

                                                return (
                                                    <tr key={index} className='transaction-single' onClick={() => handleSingleTransactionClick(data, index)}>
                                                        <td>{(new Date(data.create_date)).toLocaleString("en-GB", { hour12: true })}</td>
                                                        <td>{user ? `${user.username}` : address}</td>
                                                        <td className='text-end'>{<span style={{ color: transactionColor(tType) }}>{transactionSign(tType)} {new Intl.NumberFormat().format(data.amount)}</span>}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className='transaction-list pagado-hide-desktop'>
                            {pendingTrxData.map((data, index) => {
                                const tType = data.transaction_type;
                                const address = tType === "credit" ? data.from_address : data.to_address;
                                const user = tType === "credit" ? data.from_user : data.to_user;

                                return (
                                    <div key={index} className='card' onClick={() => handleSingleTransactionClick(data, index)}>
                                        <div className='transaction-details'>
                                            <div className='date'>{(new Date(data.create_date)).toLocaleDateString(undefined, {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                            })}</div>
                                            <div className='amount'>
                                                <img src={transactionIcon(tType)} alt='' />
                                                {<span style={{ color: transactionColor(tType) }}>
                                                    {new Intl.NumberFormat().format(data.amount)}
                                                </span>}
                                            </div>
                                        </div>
                                        <div className='author-details'>
                                            <div className='img'>
                                                <img src='/images/pagado_icon_xs.png' alt='' />
                                            </div>
                                            <div className='title'>{user ? `${user.username}` : addressExcerpt(address)}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </> : <></>}

                <div className='section mt-4'>
                    <div className='section-heading'>
                        <h2 className='title'>Transactions</h2>
                    </div>
                    <div className='transaction-list-wrapper'>
                        {trxData.length > 0 ? <>
                            <div className='table-responsive pagado-hide-mobile'>
                                <div className='card'>
                                    <table className='transaction-table table table-striped'>
                                        <thead>
                                            <tr>
                                                <th scope='col'>Date</th>
                                                <th scope='col'>From / To</th>
                                                <th scope='col'>Amount</th>
                                                <th className='text-end' scope='col'>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {trxData.map((data, index) => {
                                                const tType = data.transaction_type;
                                                const address = tType === "credit" ? data.from_address : data.to_address;
                                                const user = tType === "credit" ? data.from_user : data.to_user;

                                                return (
                                                    <tr key={index} className='transaction-single' onClick={() => handleSingleTransactionClick(data, index)}>
                                                        <td>{(new Date(data.transaction_date)).toLocaleString("en-GB", { hour12: true })}</td>
                                                        <td>{user ? `${user.username}` : address}</td>
                                                        <td>{<span style={{ color: transactionColor(tType) }}>{transactionSign(tType)} {new Intl.NumberFormat().format(data.amount)}</span>}</td>
                                                        <td className='text-end'>{new Intl.NumberFormat().format(data.balance)}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className='transaction-list pagado-hide-desktop'>
                                {trxData.map((data, index) => {
                                    const tType = data.transaction_type;
                                    const address = tType === "credit" ? data.from_address : data.to_address;
                                    const user = tType === "credit" ? data.from_user : data.to_user;

                                    return (
                                        <div key={index} className='card' onClick={() => handleSingleTransactionClick(data, index)}>
                                            <div className='transaction-details'>
                                                <div className='date'>{(new Date(data.transaction_date)).toLocaleDateString(undefined, {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric',
                                                })}</div>
                                                <div className='amount'>
                                                    <img src={transactionIcon(tType)} alt='' />
                                                    {<span style={{ color: transactionColor(tType) }}>
                                                        {new Intl.NumberFormat().format(data.amount)}
                                                    </span>}
                                                </div>
                                            </div>
                                            <div className='author-details'>
                                                <div className='img'>
                                                    <img src='/images/pagado_icon_xs.png' alt='' />
                                                </div>
                                                <div className='title'>{user ? `${user.username}` : addressExcerpt(address)}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </> : <p className='mt-5 text-center'>No transaction data available to display</p>}
                    </div>
                </div>

                <div className='pagado-popup pagado-hide'>
                    <div id='single-transaction-details' className='container popup-content'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col'>
                                    <div className='content-table border'>
                                        <div className="entry">
                                            <div className="name">
                                                <FontAwesomeIcon icon={faHashtag} />ID:
                                            </div>
                                            <div className="value">{popupData.transaction_hash}</div>
                                        </div>
                                        <div className="entry">
                                            <div className="name">
                                                <FontAwesomeIcon icon={faCreditCard} />Type:</div>
                                            <div className="value">{popupData.transaction_type ? popupData.transaction_type.toUpperCase() : ''}</div>
                                        </div>
                                        <div className="entry">
                                            <div className="name">
                                                <FontAwesomeIcon icon={faRightFromBracket} />From:</div>
                                            <div className="value">{popupData.from_address}</div>
                                        </div>
                                        <div className="entry">
                                            <div className="name">
                                                <FontAwesomeIcon icon={faRightToBracket} />To:</div>
                                            <div className="value">{popupData.to_address}</div>
                                        </div>
                                        <div className="entry">
                                            <div className="name amount">
                                                <img src="/images/millix_icon-color.png" />Amount:</div>
                                            <div className="value">{function () {
                                                const ts = transactionSign(popupData.transaction_type);
                                                return (
                                                    <>
                                                        <span style={{ color: ts.color }}>{ts.sign} </span>
                                                        {new Intl.NumberFormat().format(popupData.amount)}
                                                    </>
                                                );
                                            }()}
                                            </div>
                                        </div>
                                        <div className="entry">
                                            <div className="name">
                                                <FontAwesomeIcon icon={faCalendarCheck} />Date:</div>
                                            <div className="value">{(new Date(popupData.transaction_date || popupData.create_date)).toLocaleString("en-GB", { hour12: true })}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='popup-bg'></div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Account;
