import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../App';
import Navigation from './Navigation';
import Footer from './Footer';

function Profile() {
    const { state } = useContext(AuthContext);
    const [millixAddress, setMillixAddress] = useState('');
    const [user, setUser] = useState({});

    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_SERVER}/api/millix/address`
        ).then(res => {
            if (res.status !== 200) {
                setMillixAddress("Invalid address");
                return;
            }
            setMillixAddress(res.data.address);
        });

        if (state.id) {
            axios.post(
                `${process.env.REACT_APP_SERVER}/api/user/get/${state.id}`
            ).then(
                res => {
                    if (res.status !== 200) {
                        return;
                    }
                    setUser(res.data);
                }
            );
        }
    }, [state]);

    const handleCopy = (e) => {
        navigator.clipboard.writeText(millixAddress);
    };

    if (state.loading) {
        return <div>Loading...</div>
    }

    return (
        <>
            {!state.isAuthenticated && <Navigate replace to='/login' />}

            <Navigation />

            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>Profile</h1>
                    <h4>{Object.keys(user).length > 0 && <strong>{`${user.username}`}</strong>}</h4>
                </div>

                <div class="section mt-3 text-center">
                    <div class="avatar-section">
                        <a href="#">
                            <img src="/assets/img/sample/avatar/avatar1.jpg" alt="avatar" class="imaged w100 rounded" />
                            <span class="button">
                                <ion-icon name="camera-outline" role="img" class="md hydrated" aria-label="camera outline"></ion-icon>
                            </span>
                        </a>
                    </div>
                </div>

                <div className='section mt-4 text-center'>
                    <span className="text-success">
                        <strong className='me-1'>Millix address:</strong>
                    </span>
                    {millixAddress}
                    <FontAwesomeIcon className='copy-action ms-1' onClick={handleCopy} icon={faCopy} />
                </div>

                <div className='listview-title mt-1'>Theme</div>
                <ul class="listview image-listview text inset no-line">
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    Dark Mode
                                </div>
                                <div class="form-check form-switch  ms-2">
                                    <input class="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch2" />
                                    <label class="form-check-label" for="darkmodeSwitch2"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div className='listview-title mt-1'>Profile Settings</div>
                <ul class="listview image-listview text inset">
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>First Name</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Last Name</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Address</div>
                                <span class="text-primary">Edit</span>
                            </div>
                        </a>
                    </li>
                </ul>


                <div className='listview-title mt-1'>Security</div>
                <ul class="listview image-listview text inset">
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Update Password</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    2 Step Verification
                                </div>
                                <div class="form-check form-switch ms-2">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault3" />
                                    <label class="form-check-label" for="SwitchCheckDefault3"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Log out all devices</div>
                            </div>
                        </a>
                    </li>
                </ul>

                <div className='listview-title mt-1'>Privacy</div>
                <ul class="listview image-listview text inset">
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    Private Profile
                                </div>
                                <div class="form-check form-switch ms-2">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault2" />
                                    <label class="form-check-label" for="SwitchCheckDefault2"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div className='listview-title mt-1'>Notifications</div>
                <ul class="listview image-listview text inset">
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    Payment Alert
                                    <div class="text-muted">
                                        Send notification when new payment received
                                    </div>
                                </div>
                                <div class="form-check form-switch  ms-2">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault1" />
                                    <label class="form-check-label" for="SwitchCheckDefault1"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Notification Sound</div>
                                <span class="text-primary">Beep</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <Footer />
        </>
    );
}

export default Profile;
