/**
 * Format USD value.
 * @param {number|string} value
 * @returns
 */
export const formatCurrency = (value) => {
    return parseFloat(value).toFixed(2);
};

/**
 * Format millix in human readable form.
 * @param {number} number
 * @returns
 */
export const renderFormatCurrency = (number) => {
    return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

/**
 *  Format millix value.
 * @param {number|string} value
 * @returns
 */
export const formatMillix = (value) => {
    return Math.floor(value);
};

/**
 * Format millix in human readable form.
 * @param {number} number
 * @returns
 */
export const renderFormatMillix = (number) => {
    return Math.floor(number).toLocaleString("en-US");
};

/**
 * Thousand separate value.
 * @param {number|string} value Value
 * @param {string} delimiter Separator. Default - `Comma (,)`
 * @returns
 */
export const thousandSeparate = (value, delimiter = ',') => {
    return value
        .toString()
        .replace(/[^0-9]/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
};
