import Footer from "./Footer";
import Navigation from "./Navigation";

function About() {
    return (
        <>
            <Navigation />
            <div id="appCapsule">
                <div className="section mt-4 text-center">
                    <h1>About</h1>
                    <h4>Who we are and what we do</h4>
                </div>

                <div className="container mt-4">
                    <div className="row">
                        <div className="col">
                            <h3 className="mt-4">Who We Are</h3>
                            <p>Welcome to Pagado, your gateway to effortless millix transactions both online and in the real world. Pagado is your go-to platform for harnessing the power of millix, the lightning-fast cryptocurrency designed for scale, speed, and sustainability.</p>

                            <h3 className="mt-4">Our Mission</h3>
                            <p>At Pagado, we're on a mission to revolutionize the way you transact with millix. We believe in making cryptocurrency accessible and practical for everyday use. Whether you're an online merchant looking to expand your payment options or a customer eager to embrace the future of digital transactions, Pagado is here to simplify and enhance your financial interactions.</p>

                            <h3 className="mt-4">The Millix Advantage</h3>
                            <p>Millix isn't just any cryptocurrency; it's a game-changer. It's lightning-fast, ensuring your transactions are completed in the blink of an eye. It's cost-effective, saving you money on fees that can add up with traditional payment methods. Plus, it's environmentally friendly, with low energy consumption that aligns with our commitment to sustainability.</p>

                            <h3 className="mt-4">What We Offer</h3>
                            <h4>For Shoppers</h4>
                            <ul>
                                <li>Effortless Transactions: Say goodbye to lengthy payment processes. With Pagado, spending millix is as easy as a few clicks or taps.</li>
                                <li>Versatile Payment Options: Use millix for online shopping, in-store purchases, or even at concerts and events. The possibilities are endless.</li>
                                <li>Security: Rest easy knowing your transactions are protected by state-of-the-art security measures.</li>
                            </ul>

                            <h4>For Businesses</h4>
                            <ul>
                                <li>Expand Your Customer Base: Attract a global audience by offering millix as a payment option on your website or in your brick-and-mortar store.</li>
                                <li>Streamlined Integration: Our seamless integration tools make it simple to start accepting millix payments in no time.</li>
                                <li>Currency Conversion (Coming Soon): Convert millix to your preferred currency effortlessly, ensuring your business remains flexible.</li>
                            </ul>

                            <h3 className="mt-4">Our Vision</h3>
                            <p>We envision a world where millix is not just a cryptocurrency but a part of everyday life. With Pagado, we're taking the first steps towards making this vision a reality. Join us on this exciting journey as we pave the way for a future where millix is universally accepted, effortlessly spent, and seamlessly integrated into our financial world.</p>

                            <h3 className="mt-4">Get Started Today!</h3>
                            <p>Ready to embrace the future of payments? Join Pagado and experience the convenience and efficiency of millix transactions. Whether you're an individual looking to explore the world of cryptocurrency or a business ready to expand your horizons, we're here to make it happen. </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default About;
